import { useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { useSwitchMode } from "../contexts/SwitchModeContextProvider";
import BeekeeperRouter from "./Beekeeper/Router";
import GrowerRouter from "./Grower/Router";

/**

 * This component is the main wrapper for the rest of the application
 */
const App = () => {
	const { mode } = useSwitchMode();

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
		libraries: ["visualization"],
	});

	return isLoaded ? (
		<BrowserRouter>
			{mode === "grower" && <GrowerRouter />}
			{mode === "beekeeper" && <BeekeeperRouter />}
		</BrowserRouter>
	) : (
		<></>
	);
};

export default App;
