import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import initYard from "../../../constants/initYard";
import { useYards } from "../../../contexts/grower/YardsContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import BackIcon from "../../../images/icon-back.svg";
import paths from "../../paths.jsx";
import NameAndSizePopUp from "./components/NameAndSizePopUp";
import Map from "./Map";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "100%",
			[theme.breakpoints.only("xs")]: {
				paddingTop: 70,
			},
			[theme.breakpoints.up("sm")]: {
				paddingTop: 70,
				padding: 30,
			},
		},
		pageTitle: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		mapContainer: {
			position: "relative",
			width: "100%",
			height: "100%",
		},
		button: {
			position: "absolute",
			bottom: theme.dimensions.indent,
			right: theme.dimensions.indent * 2,
			height: 60,
			width: 120,
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fonts.xxmedium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xsmall,
			},
		},
		titleAndIcon: {
			width: "100%",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			[theme.breakpoints.up("md")]: {
				marginRight: "42%",
			},
			[theme.breakpoints.only("sm")]: {
				marginRight: "35%",
			},
		},
		title: {
			marginBottom: theme.dimensions.indent / 2,
		},
		backButton: {
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		icon: {
			height: 30,
			paddingLeft: 40,
		},
	}),
);

/**
 * YardCreateMap page component
 *
 * This page display all orchard of user on a map
 */
const YardCreateMap = () => {
	const styles = useStyles();
	const history = useHistory();
	const { showMessage } = useNotification();

	const { addYard, isLoading } = useYards();
	const [yard, setYard] = React.useState(initYard);

	const [isOpen, setIsOpen] = React.useState(true);
	const closeModal = () => setIsOpen(false);

	const createYard = () =>
		addYard({
			...yard,
			yardValidatedByGrower: new Date().getTime(),
		})
			.then((yard) =>
				history.push(paths.dropMap.baseUrl.replace(":yardId", yard.id)),
			)
			.then(() =>
				showMessage({
					text: i18n.t("YardCreateMap.events.success.addYard"),
					severity: "success",
				}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t("YardCreateMap.events.errors.addYard"),
					severity: "error",
				}),
			);

	const setYardName = (name) => setYard((oldYard) => ({ ...oldYard, name }));
	const setYardSize = (size) =>
		setYard((oldYard) => ({
			...oldYard,
			size: Number(size.replace(",", ".")),
		}));
	const setYardCrops = (crops) => setYard((oldYard) => ({ ...oldYard, crops }));
	const setYardAdditionalInfo = (additionalInfo) =>
		setYard((oldYard) => ({ ...oldYard, additionalInfo }));

	const addVertice = (coordinates) => {
		setYard((oldYard) => {
			const newVertices = [...oldYard.vertices, coordinates];
			return { ...oldYard, vertices: newVertices };
		});
	};

	const updateVertice = (coordinate, index) =>
		setYard((oldYard) => {
			// Do not remove the copy, otherwise breaks because we update an immutable
			const newYard = {
				...oldYard,
				vertices: [...oldYard.vertices],
			};
			newYard.vertices.splice(index, 1, coordinate);
			return newYard;
		});

	const removeVertice = (index) => {
		setYard((oldYard) => {
			const newYard = {
				...oldYard,
				vertices: [...oldYard.vertices],
			};
			newYard.vertices.splice(index, 1);
			return newYard;
		});
	};

	const onPressOnMap = (position) =>
		addVertice({
			latitude: position.latLng.lat(),
			longitude: position.latLng.lng(),
		});

	const onMarkerDragEnd = (event, index) =>
		updateVertice(
			{
				latitude: event.latLng.lat(),
				longitude: event.latLng.lng(),
			},
			index,
		);

	return (
		<div className={styles.container}>
			<NameAndSizePopUp
				yard={yard}
				setName={setYardName}
				setSize={setYardSize}
				setCrops={setYardCrops}
				setAdditionalInfo={setYardAdditionalInfo}
				isOpen={isOpen}
				close={closeModal}
			/>
			<div className={styles.titleAndIcon}>
				<ButtonBase
					className={styles.backButton}
					onClick={() => history.goBack()}
				>
					<img src={BackIcon} alt="back" className={styles.icon} />
				</ButtonBase>
				<Typography className={styles.pageTitle}>
					{i18n.t("YardCreateMap.title")}
				</Typography>
			</div>
			<div className={styles.mapContainer}>
				<Map
					yard={yard}
					onPressOnMap={onPressOnMap}
					onMarkerDragEnd={onMarkerDragEnd}
					removeVertice={removeVertice}
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
					loadingElement={<div style={{ height: "100%", width: "100%" }} />}
					containerElement={<div style={{ height: "100%", width: "100%" }} />}
					mapElement={<div style={{ height: "100%", width: "100%" }} />}
				/>
				{!isOpen && (
					<Button
						label={i18n.t("YardCreateMap.confirm")}
						onClick={createYard}
						type="secondary"
						style={styles.button}
						styleText={styles.buttonText}
						disabled={yard.vertices.length < 3}
						loading={isLoading}
					/>
				)}
			</div>
		</div>
	);
};

YardCreateMap.propTypes = {};

export default YardCreateMap;
