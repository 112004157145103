import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import { useYards } from "../../../contexts/grower/YardsContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import Pencil from "../../../images/pencil.svg";
import paths from "../../paths.jsx";
import NameAndSizePopUp from "./components/NameAndSizePopUp";
import Map from "./Map";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "100%",
			[theme.breakpoints.only("xs")]: {
				paddingTop: 70,
			},
			[theme.breakpoints.up("sm")]: {
				paddingTop: 70,
				padding: 30,
			},
		},
		pageTitle: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		mapContainer: {
			position: "relative",
			width: "100%",
			height: "100%",
		},
		button: {
			position: "absolute",
			height: 60,
			width: 120,
			bottom: theme.dimensions.indent,
			right: theme.dimensions.indent * 2,
		},
		editButton: {
			position: "absolute",
			bottom: theme.dimensions.indent * 4,
			right: theme.dimensions.indent * 2,
			borderRadius: 50,
		},
		icon: {
			marginRight: 10,
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fonts.xxmedium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xsmall,
			},
		},
		paper: {
			width: 300,
			height: 300,
		},
	}),
);

/**
 * This component is the contact page wrapper.
 */
const YardEditMap = () => {
	const styles = useStyles();
	const history = useHistory();
	const { showMessage } = useNotification();
	const { yardId } = useParams();

	const { yards, isLoading, updateYard } = useYards();
	const [yard, setYard] = React.useState();
	React.useEffect(() => {
		setYard(yards.find((y) => y.id === yardId));
	}, [yards, yardId]);

	const [isOpen, setIsOpen] = React.useState(false);
	const closeModal = () => setIsOpen(false);

	const setYardName = (name) => setYard((oldYard) => ({ ...oldYard, name }));
	const setYardSize = (size) =>
		setYard((oldYard) => ({
			...oldYard,
			size: Number(size.replace(",", ".")),
		}));
	const setYardCrops = (crops) => setYard((oldYard) => ({ ...oldYard, crops }));
	const setYardAdditionalInfo = (additionalInfo) =>
		setYard((oldYard) => ({ ...oldYard, additionalInfo }));

	const addVertice = (coordinates) => {
		setYard((oldYard) => {
			const newVertices = [...oldYard.vertices, coordinates];
			return { ...oldYard, vertices: newVertices };
		});
	};

	const updateVertice = (coordinate, index) =>
		setYard((oldYard) => {
			// Do not remove the copy, otherwise breaks because we update an immutable
			const newYard = {
				...oldYard,
				vertices: [...oldYard.vertices],
			};
			newYard.vertices.splice(index, 1, coordinate);
			return newYard;
		});

	const removeVertice = (index) => {
		setYard((oldYard) => {
			const newYard = {
				...oldYard,
				vertices: [...oldYard.vertices],
			};
			newYard.vertices.splice(index, 1);
			return newYard;
		});
	};

	const onPressOnMap = (position) =>
		addVertice({
			latitude: position.latLng.lat(),
			longitude: position.latLng.lng(),
		});

	const onMarkerDragEnd = (event, index) =>
		updateVertice(
			{
				latitude: event.latLng.lat(),
				longitude: event.latLng.lng(),
			},
			index,
		);

	const onConfirm = () =>
		updateYard(yard, yardId)
			.then(() => {
				history.push(paths.allMyOrchard.baseUrl);
				showMessage({
					text: i18n.t("YardEditMap.events.success.editYard"),
					severity: "success",
				});
			})
			.catch(() =>
				showMessage({
					text: i18n.t("YardEditMap.events.errors.editYard"),
					severity: "error",
				}),
			);

	return (
		<div className={styles.container}>
			<Typography className={styles.pageTitle}>
				{i18n.t("YardEditMap.title")}
			</Typography>
			<div className={styles.mapContainer}>
				{yard && (
					<>
						<Map
							yard={yard}
							onPressOnMap={onPressOnMap}
							onMarkerDragEnd={onMarkerDragEnd}
							removeVertice={removeVertice}
							googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
							loadingElement={<div style={{ height: "100%", width: "100%" }} />}
							containerElement={
								<div style={{ height: "100%", width: "100%" }} />
							}
							mapElement={<div style={{ height: "100%", width: "100%" }} />}
						/>
						<Button
							icon={Pencil}
							label={i18n.t("YardEditMap.edit")}
							onClick={() => setIsOpen(true)}
							type="secondary"
							style={styles.editButton}
							styleIcon={styles.icon}
							styleText={styles.buttonText}
							disabled={!yard || yard.vertices.length < 3}
							loading={isLoading}
						/>
						<NameAndSizePopUp
							yard={yard}
							setName={setYardName}
							setSize={setYardSize}
							setCrops={setYardCrops}
							setAdditionalInfo={setYardAdditionalInfo}
							isOpen={isOpen}
							close={closeModal}
						/>
						<Button
							label={i18n.t("YardEditMap.confirm")}
							onClick={onConfirm}
							type="secondary"
							style={styles.button}
							yardId={yardId}
							styleText={styles.buttonText}
							disabled={!yard || yard.vertices.length < 3}
							loading={isLoading}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default YardEditMap;
