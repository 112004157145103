import { ButtonBase, Paper, Typography, Switch } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useApi } from "../../../contexts/ApiContextProvider";
import { useYards } from "../../../contexts/grower/YardsContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import BackIcon from "../../../images/icon-back.svg";
import Map from "./Map";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "100%",
			[theme.breakpoints.only("xs")]: {
				paddingTop: 70,
			},
			[theme.breakpoints.up("sm")]: {
				paddingTop: 70,
				padding: 30,
			},
		},
		title: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		mapContainer: {
			position: "relative",
			width: "100%",
			height: "100%",
		},
		informationOverlay: {
			display: "flex",
			flexDirection: "row",
			position: "absolute",
			bottom: theme.dimensions.indent,
			textAlign: "center",
			[theme.breakpoints.only("xs")]: {
				left: "calc(50% - 115px)",
				width: 230,
			},
			[theme.breakpoints.up("sm")]: {
				left: "calc(50% - 250px)",
				width: 500,
			},
		},
		titleAndIcon: {
			width: "100%",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			[theme.breakpoints.up("md")]: {
				marginRight: "42%",
			},
			[theme.breakpoints.only("sm")]: {
				marginRight: "35%",
			},
		},
		icon: {
			height: 30,
		},
		backButton: {
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		bubbleInformation: {
			height: 60,
			width: 150,
			backgroundColor: theme.colors.map.background.pinkGrey,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			[theme.breakpoints.only("xs")]: {
				height: 40,
				width: 80,
				fontSize: theme.fontSizes.xsmall,
				marginLeft: theme.dimensions.indent / 2,
				marginRight: theme.dimensions.indent / 2,
			},
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.dimensions.indent,
				marginRight: theme.dimensions.indent,
			},
		},
		bubbleText: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xsmall,
			},
		},
		switchesContainer: {
			position: "absolute",
			bottom: theme.dimensions.indent,
			left: theme.dimensions.indent * 2,
			backgroundColor: theme.colors.backgrounds.primary,
			borderRadius: theme.roundness,
			minWidth: 225,
		},
		switchContainer: {
			flexDirection: "row",
			display: "flex",
		},
		switchText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginTop: "auto",
			marginBottom: "auto",
			marginLeft: theme.dimensions.indent / 2,
			marginRight: "auto",
		},
	}),
);

/**
 * YardsMap page component
 *
 * This page display all orchard of user on a map
 */
const YardsMap = () => {
	const styles = useStyles();
	const history = useHistory();

	const { yards } = useYards();
	const { showMessage } = useNotification();
	const { api } = useApi();

	const [dropsMetricsAreVisible, setDropsMetricsAreVisible] = React.useState(
		false,
	);
	const toogleDropsMetricsVisibility = () =>
		setDropsMetricsAreVisible((v) => !v);

	const [drops, setDrops] = React.useState([]);
	React.useEffect(() => {
		api
			.appGrowerDropsGet()
			.then(setDrops)
			.catch((err) =>
				showMessage({
					severity: "error",
					text: err.message,
				}),
			);
	}, [api, showMessage]);

	return (
		<div className={styles.container}>
			<div className={styles.titleAndIcon}>
				<ButtonBase
					className={styles.backButton}
					onClick={() => history.goBack()}
				>
					<img src={BackIcon} alt="back" className={styles.icon} />
				</ButtonBase>
				<Typography className={styles.title}>
					{i18n.t("AllMyOrchard.title")}
				</Typography>
			</div>
			<div className={styles.mapContainer}>
				<Map
					yards={yards}
					drops={drops}
					dropsMetricsAreVisible={dropsMetricsAreVisible}
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
					loadingElement={<div style={{ height: "100%", width: "100%" }} />}
					containerElement={<div style={{ height: "100%", width: "100%" }} />}
					mapElement={<div style={{ height: "100%", width: "100%" }} />}
				/>
				<div className={styles.informationOverlay}>
					<Paper className={styles.bubbleInformation}>
						<Typography className={styles.bubbleText}>
							{`${yards.length} ${i18n.t("YardsMap.legends.orchards")}`}
						</Typography>
					</Paper>
					<Paper className={styles.bubbleInformation}>
						<Typography className={styles.bubbleText}>
							{`${drops.reduce(
								(acc, d) => acc + d.numberOfBeehives,
								0,
							)} ${i18n.t("YardsMap.legends.hives")}`}
						</Typography>
					</Paper>
				</div>

				<div className={styles.switchesContainer}>
					<div className={styles.switchContainer}>
						{dropsMetricsAreVisible ? (
							<Typography className={styles.switchText} variant="body2">
								{i18n.t("DropMap.hideDropsMetrics")}
							</Typography>
						) : (
							<Typography className={styles.switchText} variant="body2">
								{i18n.t("DropMap.showDropsMetrics")}
							</Typography>
						)}
						<Switch
							checked={dropsMetricsAreVisible}
							onChange={toogleDropsMetricsVisibility}
							color="primary"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

YardsMap.propTypes = {
	location: PropTypes.object,
};

export default YardsMap;
