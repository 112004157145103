import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { GoogleMap, InfoBox, Marker, Polygon } from "@react-google-maps/api";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import PinIcon from "../../../../images/pin.svg";
import theme from "../../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		labelContainer: {
			borderRadius: theme.roundness,
			backgroundColor: theme.colors.map.background.primary,
			padding: theme.dimensions.indent / 4,
			border: "solid 1px black",
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xsmall,
			color: theme.colors.texts.primary,
			textAlign: "left",
			marginLeft: "auto",
		},
		summaryText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xsmall,
			color: theme.colors.texts.primary,
			textAlign: "right",
		},
		infoContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
		},
	}),
);

const fresno = {
	lat: 36.7378,
	lng: -119.7871,
};

const getRegion = (yards) =>
	yards.length > 0
		? {
				lat:
					yards.reduce(
						(total, yard) =>
							total +
							yard.vertices.reduce((acc, v) => acc + v.latitude, 0) /
								yard.vertices.length,
						0,
					) / yards.length,
				lng:
					yards.reduce(
						(total, yard) =>
							total +
							yard.vertices.reduce((acc, v) => acc + v.longitude, 0) /
								yard.vertices.length,
						0,
					) / yards.length,
		  }
		: fresno;

const MapComponent = ({ yards, drops, dropsMetricsAreVisible }) => {
	const styles = useStyles();
	const mapRef = React.useRef();
	return (
		<GoogleMap
			initialCenter={getRegion(yards)}
			center={getRegion(yards)}
			ref={mapRef}
			zoom={13}
			options={{
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				mapTypeId: "hybrid",
			}}
			mapContainerStyle={{ height: "100%" }}
		>
			{drops.map((drop) => (
				<Marker
					key={drop.id}
					icon={PinIcon}
					position={{
						lat: drop.coordinate.latitude,
						lng: drop.coordinate.longitude,
					}}
					draggable={false}
				>
					<InfoBox
						position={{
							lat: drop.coordinate.latitude,
							lng: drop.coordinate.longitude,
						}}
						options={{
							isHidden: !dropsMetricsAreVisible,
							alignBottom: true,
							pixelOffset: new window.google.maps.Size(-35, -55),
							closeBoxURL: "",
							enableEventPropagation: false,
						}}
					>
						<div className={styles.labelContainer}>
							<div className={styles.infoContainer}>
								<Typography className={styles.summaryText}>
									{i18n.t("DropMap.summary.FOB")}
								</Typography>
								<Typography className={styles.boldText}>
									{drop.fobAverage}
								</Typography>
							</div>
							<div className={styles.infoContainer}>
								<Typography className={styles.summaryText}>
									{i18n.t("DropMap.summary.numberOfBeehives")}
								</Typography>
								<Typography className={styles.boldText}>
									{drop.numberOfBeehives}
								</Typography>
							</div>
						</div>
					</InfoBox>
				</Marker>
			))}
			{yards.map((yard) => (
				<Polygon
					key={yard.id}
					path={yard.vertices.map((el) => ({
						lat: el.latitude,
						lng: el.longitude,
					}))}
					options={{
						strokeColor: theme.colors.backgrounds.yellow,
						fillColor: theme.colors.backgrounds.yellow,
					}}
				/>
			))}
		</GoogleMap>
	);
};

MapComponent.propTypes = {
	yards: PropTypes.array,
	drops: PropTypes.array,
	dropsMetricsAreVisible: PropTypes.array,
};

export default MapComponent;
