import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { GoogleMap, InfoBox, Marker, Polygon } from "@react-google-maps/api";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useYards } from "../../../../contexts/grower/YardsContextProvider";
import PinIcon from "../../../../images/pin.svg";
import theme from "../../../../theme/theme";
import YardHeatMap from "../components/YardHeatMap";

const useStyles = makeStyles((theme) =>
	createStyles({
		labelContainer: {
			borderRadius: theme.roundness,
			backgroundColor: theme.colors.map.background.primary,
			padding: theme.dimensions.indent / 4,
			border: "solid 1px black",
			width: 100,
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xsmall,
			color: theme.colors.texts.primary,
			textAlign: "left",
			marginLeft: "auto",
		},
		summaryText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xsmall,
			color: theme.colors.texts.primary,
			textAlign: "right",
		},
		infoContainer: {
			display: "flex",
			flexDirection: "row",
			// justifyContent: "space-around",
		},
	}),
);

const fresno = {
	lat: 36.7378,
	lng: -119.7871,
};

const MapComponent = ({
	yard,
	drops,
	editable,
	dropsMetricsAreVisible,
	heatmapIsVisible,
	selectedDrop,
	onClickOnMap,
	onClickOnMarker,
	onMarkerDragEnd,
	deleteDrop,
	editDrop,
}) => {
	const styles = useStyles();
	const [currentRegion, setCurrentRegion] = React.useState(fresno);
	const [isLoaded, setIsLoaded] = React.useState(false);

	const { yards } = useYards();

	/**
	 * Center map on the yard on load
	 */
	React.useEffect(() => {
		if (yard && !isLoaded) {
			const region =
				yard.vertices.length > 0
					? yard.vertices.reduce(
							(region, v) => ({
								lat: region.lat + v.latitude,
								lng: region.lng + v.longitude,
							}),
							{
								lat: 0,
								lng: 0,
							},
					  )
					: fresno;
			setCurrentRegion({
				lat: region.lat / yard.vertices.length,
				lng: region.lng / yard.vertices.length,
			});
			setIsLoaded(true);
		}
	}, [yard, isLoaded]);

	return (
		<GoogleMap
			initialCenter={currentRegion}
			center={currentRegion}
			zoom={heatmapIsVisible ? 15 : 14.999}
			onClick={(position) => {
				if (editable) {
					onClickOnMap({
						latitude: position.latLng.lat(),
						longitude: position.latLng.lng(),
					});
				}
			}}
			options={{
				scrollwheel: !heatmapIsVisible,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				mapTypeId: "hybrid",
			}}
			mapContainerStyle={{ height: "100%" }}
		>
			{heatmapIsVisible && <YardHeatMap yard={yard} drops={drops} />}
			{drops.map((drop) => (
				<Marker
					key={drop.id}
					icon={PinIcon}
					position={{
						lat: drop.coordinate.latitude,
						lng: drop.coordinate.longitude,
					}}
					draggable={editable}
					onClick={() => onClickOnMarker(drop)}
					onDragEnd={(position) => onMarkerDragEnd(drop, position)}
				>
					<InfoBox
						position={{
							lat: drop.coordinate.latitude,
							lng: drop.coordinate.longitude,
						}}
						options={{
							isHidden: !(
								dropsMetricsAreVisible || selectedDrop?.id === drop.id
							),
							alignBottom: true,
							pixelOffset: new window.google.maps.Size(-50, -55),
							closeBoxURL: "",
							enableEventPropagation: false,
						}}
					>
						<div className={styles.labelContainer}>
							<div className={styles.infoContainer}>
								<Typography className={styles.summaryText}>
									{i18n.t("DropMap.summary.FOB")}
								</Typography>
								<Typography className={styles.boldText}>
									{drop.fobAverage}
								</Typography>
							</div>
							<div className={styles.infoContainer}>
								<Typography className={styles.summaryText}>
									{i18n.t("DropMap.summary.numberOfBeehives")}
								</Typography>
								<Typography className={styles.boldText}>
									{drop.numberOfBeehives}
								</Typography>
							</div>
							{selectedDrop?.id === drop.id && editable && (
								<div>
									<IconButton onClick={editDrop}>
										<EditIcon style={{ height: 15, width: 15 }} />
									</IconButton>
									<IconButton onClick={deleteDrop}>
										<DeleteIcon style={{ height: 15, width: 15 }} />
									</IconButton>
								</div>
							)}
						</div>
					</InfoBox>
				</Marker>
			))}

			<Polygon
				key={yard.id}
				path={yard.vertices.map((el) => ({
					lat: el.latitude,
					lng: el.longitude,
				}))}
				options={{
					strokeColor: theme.colors.backgrounds.yellow,
					fillColor: theme.colors.backgrounds.yellow,
					clickable: false,
				}}
			/>
			{yards
				.filter((y) => y.id !== yard.id)
				.map((y) => (
					<Polygon
						key={y.id}
						path={y.vertices.map((el) => ({
							lat: el.latitude,
							lng: el.longitude,
						}))}
						options={{
							strokeColor: theme.colors.backgrounds.white,
							fillColor: theme.colors.backgrounds.white,
						}}
					/>
				))}
		</GoogleMap>
	);
};

MapComponent.propTypes = {
	yard: PropTypes.object,
	drops: PropTypes.array,
	editable: PropTypes.bool,
	dropsMetricsAreVisible: PropTypes.bool,
	heatmapIsVisible: PropTypes.bool,
	selectedDrop: PropTypes.object,
	onClickOnMap: PropTypes.func,
	onClickOnMarker: PropTypes.func,
	onMarkerDragEnd: PropTypes.func,
	deleteDrop: PropTypes.func,
	editDrop: PropTypes.func,
};

export default MapComponent;
