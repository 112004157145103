import { GoogleMap, Marker } from "@react-google-maps/api";
import * as React from "react";

const MapUbees = () => {
	const mapRef = React.useRef();

	const ubees = { lat: 36.75442418416887, lng: -120.12252538924528 };

	return (
		<GoogleMap
			center={ubees}
			zoom={13}
			ref={mapRef}
			mapContainerStyle={{ height: "100%" }}
			options={{
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				mapTypeId: "hybrid",
			}}
		>
			<Marker position={ubees} />
		</GoogleMap>
	);
};

export default MapUbees;
