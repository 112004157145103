import { IconButton } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { GoogleMap, InfoBox, Marker, Polygon } from "@react-google-maps/api";
import PropTypes from "prop-types";
import * as React from "react";
import { useYards } from "../../../../contexts/grower/YardsContextProvider";
import PinIcon from "../../../../images/pin.svg";
import theme from "../../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		button: {
			borderRadius: theme.roundness,
			backgroundColor: theme.colors.map.background.primary,
			padding: theme.dimensions.indent / 4,
			border: "solid 1px black",
		},
	}),
);

const fresno = {
	lat: 36.7378,
	lng: -119.7871,
};

const MapComponent = ({
	yard,
	onPressOnMap,
	onMarkerDragEnd,
	removeVertice,
}) => {
	const mapRef = React.useRef();
	const { yards } = useYards();

	const styles = useStyles();

	const [currentRegion, setCurrentRegion] = React.useState(fresno);
	const [loaded, setLoaded] = React.useState(false);
	const [selectedPin, setSelectedPin] = React.useState(undefined);

	/**
	 * Center map on the yard on load
	 */
	React.useEffect(() => {
		if (yard && !loaded) {
			const region =
				yard.vertices.length > 0
					? yard.vertices.reduce(
							(region, v) => ({
								lat: region.lat + v.latitude,
								lng: region.lng + v.longitude,
							}),
							{
								lat: 0,
								lng: 0,
							},
					  )
					: fresno;
			setCurrentRegion({
				lat: region.lat / yard.vertices.length,
				lng: region.lng / yard.vertices.length,
			});
			setLoaded(true);
		}
	}, [yard, loaded]);

	return (
		<GoogleMap
			initialCenter={currentRegion}
			center={currentRegion}
			ref={mapRef}
			zoom={13}
			onClick={(e) => {
				onPressOnMap(e);
				setSelectedPin();
			}}
			options={{
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				mapTypeId: "hybrid",
			}}
			mapContainerStyle={{ height: "100%" }}
		>
			{yard?.vertices &&
				yard.vertices.map((vertex, idx) => (
					<Marker
						name="Current location"
						key={idx}
						icon={PinIcon}
						position={{
							lat: vertex.latitude,
							lng: vertex.longitude,
						}}
						draggable
						onClick={() => setSelectedPin((n) => (idx !== n ? idx : undefined))}
						onDragEnd={(event) => onMarkerDragEnd(event, idx)}
					>
						<InfoBox
							position={{
								lat: vertex.latitude,
								lng: vertex.longitude,
							}}
							options={{
								isHidden: selectedPin !== idx,
								alignBottom: true,
								pixelOffset: new window.google.maps.Size(-22, -55),
								closeBoxURL: "",
								enableEventPropagation: false,
							}}
						>
							<IconButton
								className={styles.button}
								onClick={() => {
									removeVertice(idx);
									setSelectedPin();
								}}
							>
								<DeleteIcon />
							</IconButton>
						</InfoBox>
					</Marker>
				))}
			{yard?.vertices && (
				<Polygon
					path={yard.vertices.map((el) => ({
						lat: el.latitude,
						lng: el.longitude,
					}))}
					options={{
						strokeColor: theme.colors.backgrounds.yellow,
						fillColor: theme.colors.backgrounds.yellow,
					}}
				/>
			)}
			{yards
				.filter((y) => y.id !== yard.id)
				.map((y) => (
					<Polygon
						key={y.id}
						path={y.vertices.map((el) => ({
							lat: el.latitude,
							lng: el.longitude,
						}))}
						options={{
							strokeColor: theme.colors.backgrounds.white,
							fillColor: theme.colors.backgrounds.white,
						}}
					/>
				))}
		</GoogleMap>
	);
};

MapComponent.propTypes = {
	yard: PropTypes.object.isRequired,
	onPressOnMap: PropTypes.func.isRequired,
	onMarkerDragEnd: PropTypes.func.isRequired,
	removeVertice: PropTypes.func.isRequired,
};

export default MapComponent;
